<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valName" :placeholder="$t('device.name')"></el-input>
        </div>

        <!-- <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-select class="inp" size="small" v-model="formData.valCode" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valCode"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">关联查下级</span>
          <el-select class="inp" size="small" v-model="formData.IsStartWith" :placeholder="$t('g.choose')">
            <el-option label="否" :value="0">
            </el-option>
            <el-option label="是" :value="1">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.operate_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.online_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valAlert" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.alerts" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevName" :placeholder="$t('device.no')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
          <!-- <i @click="func.showAddDataDialog()" class="el-icon-plus header-icon"></i> -->
        </div>
      </div>
      <el-table :data="formData.tableData" @row-click="func.showDetails" :stripe="true" @expand-change="func.expand"
        style="width: 100%">
        <el-table-column type="expand">
          <template #default="scope">
            <div class="table-detail">
              <div class="charge-range" v-if="scope.row.ChargeType == 2">
                <div class="item">{{ $t('device.charge_rule') }}</div>
                <div class="item" v-for="item, idx in formData.curChargeRuleStr" :key="idx">
                  ----- {{ item }}
                </div>
              </div>
              <div class="item">{{ $t('device.site_name') }}: {{ scope.row.SiteName }}</div>
              <div class="item">{{ $t('device.life_time') }}: {{ func.toCounterTime(scope.row.LifeTime) }}</div>
              <div class="item">{{ $t('device.update_time') }}: {{ scope.row.UpdateAtStr }}
              </div>
              <div class="item">{{ $t('device.temp') }}: {{ scope.row.Temp }}</div>
              <div class="item">{{ $t('device.mac') }}: {{ scope.row.MacAddr }}</div>
              <div class="item">{{ $t('device.coordinate') }}: {{ scope.row.Longitude }},{{ scope.row.Latitude }}</div>
              <div class="item" v-if="scope.row.ImgBox">{{ $t('device.img_dev') }}: <img :src="scope.row.ImgBox" />
              </div>
              <div class="item" v-if="scope.row.ImgStore">{{ $t('device.site') }}: <img :src="scope.row.ImgStore" />
              </div>
              <div class="item" v-if="scope.row.ImgStreet">{{ $t('device.env') }}: <img :src="scope.row.ImgStreet" />
              </div>
              <div class="item">{{ $t('device.slot_num_all') }}: {{ scope.row.SlotNum }}</div>
              <div class="item">{{ $t('device.slot_num_empty') }}: {{ scope.row.SlotNumEm }}</div>
              <div class="item">{{ $t('device.slot_num_error') }}: {{ scope.row.SlotNumErr }}</div>
              <div class="item">{{ $t('device.address') }}: {{ scope.row.Address }}</div>
              <div class="item"><el-button class="btn" @click="func.openSlot(scope.row.DevId, 0)" size="small"
                  :type="'primary'">{{ $t('device.open_all_slots') }}</el-button></div>
              <div class="item" v-if="!formData.slots[scope.row.Id]">{{ $t('device.slot_loading') }}</div>
              <div class="slots" v-else>
                <div class="slot" v-for="(item, idx) in formData.slots[scope.row.Id]" :key="idx">
                  <div>{{ $t('device.slot_no') }} : {{ item.DevId }}</div>
                  <div class="deviceid">{{ $t('device.device_id') }} : {{ item.BatId }}</div>
                  <div>{{ $t('device.slot_type') }} : {{ item.SlotType ? $t('device.slot_battery') :
                    $t('device.slot_empty') }}</div>
                  <div>{{ $t('device.voltage') }} : {{ item.Voltage }}</div>
                  <div>{{ $t('device.current') }} : {{ item.Current }}</div>
                  <div>SOC : {{ item.Soc }}</div>
                  <div>SOH : {{ item.Soh }}</div>
                  <div style="width:340px">{{ $t('device.slot_warning') }} : {{ item.AlertInfo }}</div>
                  <el-button class="btn" @click="func.toggleSlotStatus(item)" size="small"
                    :type="item.Status ? 'danger' : 'success'">{{ item.Status ? $t('device.disable') :
                      $t('device.enable') }}</el-button>
                  <el-button class="btn" @click="func.openSlot(scope.row.DevId, item.DevId)" size="small"
                    :type="'primary'">{{ $t('device.open_slot') }}</el-button>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Id" label="#" width="80">
        </el-table-column>
        <el-table-column prop="Name" :label="$t('device.name')" width="140">
        </el-table-column>
        <el-table-column prop="DevId" :label="$t('device.no')" width="260">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('g.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="Specs" width="120" :label="$t('device.specs')">
        </el-table-column>
        <el-table-column prop="ModeNo" width="120" :label="$t('device.pattern')">
        </el-table-column>
        <el-table-column prop="SlotNum" width="120" :label="$t('device.slot_num_all')">
        </el-table-column>
        <el-table-column prop="ChargeMoney" width="120" :label="$t('device.moneys.charge')">
        </el-table-column>
        <el-table-column prop="SaveMoney" width="120" :label="$t('device.moneys.save')">
        </el-table-column>
        <el-table-column prop="ErrorCloseMoney" width="120" :label="$t('device.moneys.err_close')">
        </el-table-column>
        <el-table-column prop="FreeChargeTime" width="120" :label="$t('device.free_deposit_time')">
        </el-table-column>
        <el-table-column prop="CountRate" width="120" :label="$t('bill.settle_rate')">
        </el-table-column>
        <el-table-column width="100" :label="$t('device.alert')">
          <template #default="scope">
            <el-tag :type="scope.row.Alert == 0 ? 'success' : 'warning'" effect="dark">
              {{ scope.row.Alert == 0 ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="$t('device.online_status')">
          <template #default="scope">
            <el-tag :type="scope.row.IsOnline == 0 ? 'warning' : 'success'" effect="dark">
              {{ scope.row.IsOnline == 0 ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.status')">
          <template #default="scope">
            <el-tag :type="scope.row.Status == 1 ? 'success' : 'info'" effect="dark">
              {{ scope.row.Status == 1 ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('member.is_release')" v-if="isAdmin()">
          <template #default="scope">
            <el-tag :type="scope.row.IsRelease == true ? 'success' : 'info'" effect="dark">
              {{ scope.row.IsRelease == false ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column prop="UpdateAtStr" min-width="180" :label="$t('device.update_time')">
        </el-table-column>
        <el-table-column width="150" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-tag @click="func.rowDblClick(scope.row)">{{ $t('g.edit') }}</el-tag>
            <el-tag @click="func.qrcode(scope.row)" style="margin-left: 10px;">二维码</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" style="width:80%;margin-top: 0;">
      <el-descriptions border column="3">
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.name')">{{ formData.curData.Name }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DevId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.specs')">{{ formData.curData.Specs }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.pattern')">{{ formData.curData.ModeNo }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_num_all')">{{ formData.curData.SlotNum }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.moneys.charge')">{{ formData.curData.ChargeMoney
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.moneys.save')">{{ formData.curData.SaveMoney }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.moneys.err_close')">{{ formData.curData.ErrorCloseMoney
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.free_deposit_time')">{{ formData.curData.FreeChargeTime
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('bill.settle_rate')">{{ formData.curData.CountRate }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.alert')"><el-tag
            :type="formData.curData.Alert == 0 ? 'success' : 'warning'" effect="dark">
            {{ formData.curData.Alert == 0 ? $t('g.no') : $t('g.yes') }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('device.online_status')"> <el-tag
            :type="formData.curData.IsOnline == 0 ? 'warning' : 'success'" effect="dark">
            {{ formData.curData.IsOnline == 0 ? $t('g.no') : $t('g.yes') }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('device.status')"> <el-tag
            :type="formData.curData.Status == 1 ? 'success' : 'info'" effect="dark">
            {{ formData.curData.Status == 1 ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('member.is_release')" v-if="isAdmin()"> <el-tag
            :type="formData.curData.IsRelease == true ? 'success' : 'info'" effect="dark">{{ formData.curData.IsRelease
              ==
              false ? $t('g.no') : $t('g.yes') }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.site_name')">{{ formData.curData.SiteName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.life_time')">{{ func.toCounterTime(formData.curData.LifeTime)
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.temp')">{{ formData.curData.Temp }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.mac')">{{ formData.curData.MacAddr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.coordinate')">{{ formData.curData.Longitude }},{{
          formData.curData.Latitude
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.img_dev')" v-if="formData.curData.ImgBox"><img
            :src="formData.curData.ImgBox" /></el-descriptions-item>
        <el-descriptions-item :label="$t('device.site')" v-if="formData.curData.ImgStore"><img
            :src="formData.curData.ImgStore" /></el-descriptions-item>
        <el-descriptions-item :label="$t('device.env')" v-if="formData.curData.ImgStreet"><img
            :src="formData.curData.ImgStreet" /></el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_num_all')">{{ formData.curData.SlotNum }}</el-descriptions-item>

        <el-descriptions-item :label="$t('device.slot_num_empty')">{{ formData.curData.SlotNumEm
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_num_error')">{{ formData.curData.SlotNumErr
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.address')">{{ formData.curData.Address }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.update_time')">
          {{ formData.curData.UpdateAtStr }}</el-descriptions-item>

      </el-descriptions>
      <el-table :data="formData.slots[formData.curData.Id]" border
        style="margin-top:20px;text-align:center;width:100%;">
        <el-table-column prop="DevId" :label="$t('device.slot_no')" width="60"></el-table-column>
        <el-table-column prop="BatId" :label="$t('device.device_id')" width="260"></el-table-column>
        <el-table-column prop="slot_type" :label="$t('device.slot_type')">
          <template #default="scope">
            {{ scope.row.SlotType ? $t('device.slot_battery') :
              $t('device.slot_empty') }}
          </template>
        </el-table-column>
        <el-table-column prop="Voltage" :label="$t('device.voltage')">
          <template #default="scope">
            {{ scope.row.Voltage / 10 + 'V' }}
          </template>
        </el-table-column>
        <el-table-column prop="Current" :label="$t('device.current')">
          <template #default="scope">
            {{ scope.row.Current / 10 + 'A' }}
          </template>
        </el-table-column>
        <el-table-column prop="Soc" label="SOC"></el-table-column>
        <el-table-column prop="Soh" label="SOH"></el-table-column>
        <el-table-column width="180" prop="AlertInfo" :label="$t('device.slot_warning')"></el-table-column>
        <el-table-column prop="slot_status" :label="$t('device.slot_status')">
          <template #default="scope">
            <span :style="{ color: scope.row.DoorStatus == 1 ? '#E6A23C' : '#67C23A' }">{{ scope.row.DoorStatus == 1 ?
              '打开' : '关闭'
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="UpdateAtStr" :label="$t('g.update_at')" width="180"></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogVisible" width="60%">
      <el-form label-width="150px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item :label="$t('device.status')">
            <el-select class="inp" size="small" v-model="formData.curData.Status" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.updateStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('member.is_release')" v-if="isAdmin()">
            <el-select class="inp" size="small" v-model="formData.curData.IsRelease" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.releaseStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.prices.charge')">
            <el-input size="small" type="number" v-model="formData.curData.ChargeMoney"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('device.prices.deposit')">
            <el-input size="small" type="number" v-model="formData.curData.SaveMoney"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.prices.fine')">
            <el-input size="small" type="number" v-model="formData.curData.ErrorCloseMoney"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('device.free_deposit_time2')">
            <el-input size="small" type="number" v-model="formData.curData.FreeChargeTime"></el-input>
          </el-form-item>
          <el-form-item :label="$t('bill.settle_rate')">
            <el-input size="small" type="number" v-model="formData.curData.CountRate"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.longitude')">
            <el-input size="small" type="number" v-model="formData.curData.Longitude"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.latitude')">
            <el-input size="small" type="number" v-model="formData.curData.Latitude"></el-input>
          </el-form-item>
          <el-form-item :label="$t('g.operator')" v-if="isPath()">
            <el-cascader size="small" style="width: 100%;" :clearable="true" v-model="formData.curData.AgentPath"
              :props="formData.props" :options="render.cascade_options_direct" />
          </el-form-item>
          <el-form-item :label="$t('rights.agent')" v-if="!isOperator()">
            <el-cascader size="small" style="width: 100%;" :clearable="true" v-model="formData.curData.agentCode"
              :props="formData.props2" :options="render.cascade_options_direct" />
          </el-form-item>
        </div>

        <div class="charge-rule-box" v-if="formData.curData.ChargeType == 2">
          <div class="title">
            <div>{{ $t('device.charge_rule') }}</div>
            <el-button size="small" type="primary" @click="func.addRule()">{{ $t('device.add') }}</el-button>
          </div>
          <div class="content">
            <div class="item" v-for="item, idx in formData.curChargeRule" :key="idx">
              <div class="txt">{{ $t('device.power_gt') }}</div>
              <el-input size="small" type="number" v-model="item.MinPower"></el-input>
              <div class="txt">W,{{ $t('device.the_price') }}</div>
              <el-input size="small" type="number" v-model="item.Price"></el-input>
              <div class="txt">{{ $t('device.price_unit') }}</div>
              <el-button size="small" type="danger" @click="func.delRule(idx)">{{ $t('device.delete') }}</el-button>
            </div>
          </div>
        </div>

        <div class="el-form-row" v-if="formData.curData.ChargeType == 3">
          <el-form-item :label="$t('device.electric_money')">
            <el-input size="small" type="number" v-model="formData.curData.ElectricMoney"></el-input>
          </el-form-item>
        </div>

        <div class="el-form-row line-3">
          <el-form-item :label="$t('device.img_dev')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgBox' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore">
              <img v-if="formData.curData.ImgBox" :src="formData.curData.ImgBox" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('device.img_site')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgStore' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore">
              <img v-if="formData.curData.ImgStore" :src="formData.curData.ImgStore" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('device.img_env')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgStreet' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore">
              <img v-if="formData.curData.ImgStreet" :src="formData.curData.ImgStreet" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog class="qrcode" v-model="formData.dialogQrcodeVisible" style="margin-top: 100px;">
      <div>{{ formData.qrcodeMsg }} </div>
      <canvas id="qrcode" style="width:300px;height:300px;border:1px solid black;margin-top: 20px;"></canvas>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed, nextTick } from 'vue'
import { shortcuts, operators, cascade_options_direct } from '@/data/default'
import { toDay, toCounterTime } from '@/common/func'
import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import cfg from '@/common/conf'
import { user, isOperator, isAdmin, isPath } from '@/data/token'
import i18n from '@/data/i18n/index'
import QRCode from "qrcode" //引入生成二维码插件
export default {
  name: 'CboxList',
  components: {
  },
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('device.statuses.all'),
        value: -1
      },
      {
        label: t('device.statuses.enabled'),
        value: 1
      },
      {
        label: t('device.statuses.disabled'),
        value: 0
      }
    ]
    const render = {
      statuses,
      operators,
      updateStatuses: [
        {
          label: t('device.enable'),
          value: 1
        },
        {
          label: t('device.disable'),
          value: 0
        }
      ],
      releaseStatuses: [{
        label: t('g.no'),
        value: false
      }, {
        label: t('g.yes'),
        value: true
      }],
      alerts: [
        {
          label: t('device.statuses.all'),
          value: -1
        },
        {
          label: t('device.statuses.online'),
          value: 0
        },
        {
          label: t('device.statuses.offline'),
          value: 1
        }
      ],
      shortcuts,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      slots: {},
      curData: {},
      valTime: '',
      valStatus: -1,
      valAlert: -1,
      dialogVisible: false,
      dialogDisplayVisible: false,
      dialogQrcodeVisible: false,
      qrcodeMsg: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: 0,
      valDevName: '',
      curChargeRule: [],
      curChargeRuleStr: [],
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      props2: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true
      },
      IsStartWith: 1
    })
    const func = {
      showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        func.expand(row, [1])
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      //  showDetails(row,column){
      //       if(column&&column.label==t('g.operation')){
      //         return;
      //       }
      //       func.expand(row,[1])
      //       formData.curData = row;
      //       func.getImage()
      //       formData.dialogDisplayVisible = true;
      //     },
      addRule() {
        formData.curChargeRule.push({
          Id: 0,
          EboxId: 0,
          Price: "",
          MinPower: ""
        })
      },
      async delRule(idx) {
        let tmp = []
        for (let i = 0; i < formData.curChargeRule.length; i++) {
          if (idx == i) {
            let item = formData.curChargeRule[i]
            if (item.Id) {
              //同步删除数据库
              await axios.delete(`/admin/device/charge/rule?id=` + item.Id, {
              })
            }
            continue;
          }
          tmp.push(formData.curChargeRule[i])
        }
        formData.curChargeRule = tmp

      },
      qrcode(row) {
        formData.dialogQrcodeVisible = true;

        console.log(JSON.stringify(row));
        let QRCodeMsg = 'http://' + row.AgentCode.toLowerCase() + '.wxrule.smart2charge.com/device?id=' + row.DevId;
        // return
        let opts = {
          errorCorrectionLevel: "H",//容错级别
          type: "image/png",//生成的二维码类型
          quality: 0.3,//二维码质量
          margin: 5,//二维码留白边距
          width: 300,//宽
          height: 300,//高
          text: QRCodeMsg,//二维码内容
          color: {
            dark: "#333333",//前景色
            light: "#fff"//背景色
          }
        };

        formData.qrcodeMsg = QRCodeMsg
        nextTick(() => {
          let msg = document.getElementById("qrcode");
          QRCode.toCanvas(msg, QRCodeMsg, opts, function (error) {
            console.log(error)
          });
        })



      },
      async openSlot(devId, slotId) {
        try {
          await ElMessageBox.confirm(
            t('device.sure_open_slot'),
            t('tips'),
            {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
              type: 'warning',
            }
          )
          await axios.post('/admin/device/openSlot', {
            devId,
            slotId
          })
          ElMessage({
            type: 'success',
            message: t('device.cmd_sent'),
          })
        } catch (e) {

        }

      },
      toCounterTime,
      async toggleSlotStatus(item) {
        let res = await axios.post('/admin/device/disableSlot', {
          slotId: item.Id,
          disableInt: item.Status === 0 ? 0 : 1,
        })
        item.Status = 1 - item.Status
        ElMessage({
          type: 'success',
          message: t('g.success')
        })
      },
      onUploadBefore() {
        console.log("onUploadBefore")
      },
      onUploadError() {
        ElMessage({
          type: 'error',
          message: t('g.upload_failed')
        })
      },
      async expand(row, state) {
        if (state.length) {
          const res = await axios.get("/admin/box/slot?eboxId=" + row.Id)
          res.map(item => {
            console.log(item.UpdateAt, 1234)
            item.UpdateAtStr = toDay(item.UpdateAt)
          })
          formData.slots[row.Id] = res
          console.log(JSON.stringify(res[0].UpdateAtStr));

        }
        await func.loadCurChargeRule(row.Id)
      },
      onUploadSuccess(res) {
        console.log("上传结果", res)
        if (res.Code != 200) {
          return ElMessage({
            type: 'error',
            message: res.Msg
          })
        }
        const { id, url } = res.Data
        formData.curData[id] = url
      },
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      rowDblClick(row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.curData.AgentPath = row.CodePath
        formData.curData.agentCode = row.AgentCode
        func.loadCurChargeRule(row.Id)
        formData.dialogVisible = true
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      showAddDataDialog() {
        formData.curData = {
        }
        formData.dialogVisible = true
      },
      async updateData() {
        console.log("updateOrCreate Data", formData.curData)
        if (typeof (formData.curData.Status) == 'undefined') {
          ElMessage({
            type: 'error',
            message: t("device.please_choose_status")
          })
          return
        }
        if (formData.curChargeRule.length) {
          for (let item of formData.curChargeRule) {
            if (item.Price === "" || item.MinPower === "") {
              ElMessage({
                type: 'error',
                message: t("device.please_input_full_info")
              })
              return
            }
            item.Price = parseFloat(item.Price)
            item.MinPower = parseInt(item.MinPower)
          }
        }
        try {
          const params = {
            id: formData.curData.Id,
            imgBox: formData.curData.ImgBox,
            imgStore: formData.curData.ImgStore,
            imgStreet: formData.curData.ImgStreet,
            status: formData.curData.Status,
            deposit: formData.curData.Deposit,
            saveMoney: formData.curData.SaveMoney,
            chargeMoney: formData.curData.ChargeMoney,
            freeChargeTime: formData.curData.FreeChargeTime,
            errorCloseMoney: formData.curData.ErrorCloseMoney,
            countRate: formData.curData.CountRate,
            eletricMoney: formData.curData.EletricMoney,
            longitude: formData.curData.Longitude,
            latitude: formData.curData.Latitude,
            agentPath: formData.curData.AgentPath,
            agentCode: formData.curData.agentCode,
          }
          if (formData.curData.password) {
            params.password = formData.curData.password
          }
          if (formData.curData.IsRelease) {
            params.isrelease = formData.curData.IsRelease
          }
          await axios.put('/admin/box', params)
          //更新chargeRule
          if (formData.curChargeRule.length) {
            let jsonData = JSON.stringify(formData.curChargeRule)
            await axios.put('/admin/device/charge/rule', {
              eboxId: formData.curData.Id,
              data: jsonData
            })
          }
        } catch (e) {
        }
        formData.dialogVisible = false
        func.loadData(formData.curPage)
      },
      async loadCurChargeRule(eboxId) {
        formData.curChargeRule = []
        formData.curChargeRuleStr = []
        let res = await axios.get(`/admin/device/charge/rule`, {
          params: {
            eboxId
          }
        })
        formData.curChargeRule = res
        for (let idx = 0; idx < res.length; idx++) {
          let str = res[idx].Price + " " + t("device.price_unit")
          if (idx + 1 < res.length) {
            str += ` ( ${res[idx].MinPower} - ${res[idx + 1].MinPower}W)`
          } else {
            str += ` (` + t("device.great_than") + ` ${res[idx].MinPower} W)`
          }
          formData.curChargeRuleStr.push(str)
        }
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
            type: 2,
          }
          if (formData.valStatus != -1) {
            params.status = formData.valStatus
          }
          if (formData.valAlert != -1) {
            params.alert = formData.valAlert
          }
          if (formData.valName) {
            params.name = formData.valName
          }
          if (formData.valCode) {
            params.code = formData.valCode
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          if (formData.valDevName) {
            params.devName = formData.valDevName
          }
          params.IsStartWith = formData.IsStartWith
          let res = await axios.get(`/admin/box`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt)
            item.UpdateAtStr = toDay(item.UpdateAt)
            // if (item.DevType != 'null') {
            //   item.specsStr = JSON.parse(item.DevType) && JSON.parse(item.DevType).Specs
            //   item.ModeNoStr = JSON.parse(item.DevType) && JSON.parse(item.DevType).ModeNo
            // }
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      uploadUrl: cfg.uploadUrl,
      token: user.token,
      isOperator,
      isAdmin,
      isPath
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .charge-rule-box {
    >.title {
      display: flex;
      justify-content: space-around;
      font-size: 1.4rem;
      font-weight: bold;
    }

    >.content {
      >.item {
        display: flex;
        align-items: baseline;
        border: 1px solid #d9d9d9;
        padding: 0.25rem 0.625rem;
        border-radius: 0.3rem;
        margin-bottom: 0.625rem;

        .txt {}

        :deep(.el-input) {
          width: 6rem;
          margin: 0 1rem;
        }

        :deep(.el-button) {
          margin-left: 1rem;
        }
      }
    }
  }

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;

    .charge-range {
      margin-bottom: 1rem;
      font-weight: 700;
    }

    .slots {
      margin-top: 1rem;

      .slot {
        display: flex;
        margin-bottom: 0.5rem;

        >div {
          width: 10rem;

          &:first-child {
            width: 6rem;
          }
        }
      }
    }

    .item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        min-width: 33%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
    margin-top: 0px;
  }
}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>
